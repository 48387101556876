import {
  FieldErrorProps,
  Group,
  GroupProps,
  FieldError as RACFieldError,
  Input as RACInput,
  InputProps as RACInputProps,
  Label as RACLabel,
  LabelProps as RACLabelProps,
  Text,
  TextProps,
  composeRenderProps,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { composeTailwindRenderProps } from "./utils";

const labelStyles = tv({
  base: "block text-base font-medium",
  variants: {
    variant: {
      primary: "text-primary-900",
      filled: "text-primary-900",
      error: "text-red-500",
      secondary: "text-primary-800",
      information: "text-information-main-1",
    },
  },
});

type LabelProps = RACLabelProps & {
  variant?: "primary" | "secondary" | "error" | "information" | "filled";
};

/**
 * Label component used in Fields components
 * @param props object containing at least
 * variant: tailwind variant to set styles to label component
 */
export function Label(props: LabelProps) {
  return (
    <RACLabel
      {...props}
      className={twMerge(
        labelStyles({ variant: props.variant }),
        props.className
      )}
    />
  );
}

/**
 * Description component used in Fields components
 * @param props object containing at least
 * variant: tailwind variant to set styles to description component
 */
export function Description(props: TextProps) {
  return (
    <Text
      {...props}
      slot="description"
      className={twMerge("text-sm text-secondary-main-50", props.className)}
    />
  );
}

/**
 * FieldError component used in Fields components
 */
export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "text-xs text-red-500"
      )}
    />
  );
}

export const inputStylesColorVariants = {
  primary:
    "text-primary-900 bg-primary-25 ring-primary-50 placeholder:text-txt-tertiary focus:ring-secondary-main-100 font-medium",
  error:
    "ring-red-100 focus:ring-red-500 bg-red-25 text-red-500 font-medium placeholder:text-red-500",
  secondary:
    "text-primary-900 bg-white !placeholder:text-primary-900 ring-primary-50 focus:ring-secondary-main-100",
  filled:
    "text-primary-700 bg-primary-25 ring-primary-25 placeholder:text-primary-700 focus:ring-secondary-main-100",
};

export const fieldBorderStyles = tv({
  variants: {
    variant: inputStylesColorVariants,
    isInvalid: {
      true: "focus:ring-red-500 bg-red-25 text-red-500",
    },
    isDisabled: {
      true: "opacity-50 bg-sidebar-gray-hover pointer-events-none",
    },
  },
});

export const fieldGroupStyles = tv({
  // extend: focusRing,
  base: "group flex items-center overflow-hidden ring-1 ring-inset",
  variants: {
    ...fieldBorderStyles.variants,
    isFocusWithin: {
      true: "ring-2",
      false: "",
    },
    open: {
      true: "rounded-t",
      false: "rounded",
    },
  },
  compoundVariants: [
    {
      isFocusWithin: true,
      variant: ["primary", "secondary"],
      class: "ring-secondary-main-100",
    },
    {
      isFocusWithin: true,
      variant: "error",
      class: "ring-red-500",
    },
    { isFocusWithin: true, open: true, class: "ring-1 ring-primary-50" },
  ],
});

type FieldGroupProps = GroupProps & {
  variant?: "primary" | "error" | "secondary" | "filled";
  open?: boolean;
};

/**
 * FieldGroup component used in other input field components
 * @param props object containing at least
 * variant: tailwind variant to set styles to description component
 */
export function FieldGroup(fieldGroupProps: FieldGroupProps) {
  const { variant, open, ...props } = fieldGroupProps;

  return (
    <Group
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({
          ...renderProps,
          className,
          variant,
          open,
        })
      )}
    />
  );
}

const inputStyles = tv({
  base: "w-full flex-1 min-w-0 outline-0 border-0 text-base font-normal",
  variants: {
    compact: {
      false: "px py",
      true: "px-2 py-2",
    },
    inGroup: {
      true: "bg-transparent ring-0 !ring-transparent",
      false: "bg-white shadow-sm ring-1 ring-inset focus:ring-2",
    },
  },
});

type InputProps = RACInputProps & {
  variant?: "error" | "primary" | "secondary" | "filled";
  inGroup?: boolean;
  compact?: boolean;
};

/**
 * Input component used in other input field components
 * @param props object containing at least
 * variant: tailwind variant to set styles to description component
 * inGroup: boolean indicating if the input takes part of a FieldGroup component or not
 */
export function Input(inputProps: InputProps) {
  const { variant, inGroup, compact, ...props } = inputProps;
  return (
    <RACInput
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        inputStyles({ inGroup: !!inGroup, compact })
      )}
    />
  );
}
